.root {
  display: flex;
  height: 57px;
  justify-content: center;
  /* padding: 13px 0 0; */
  /* justify-content: flex-start; */
  @media (max-width: 575px) {
    /* padding: 13px 0 0; */
  }
  @media (max-width: 768px) {
    justify-content: space-evenly;
  }
  @media (max-width: 991px) {
  }
}

.tab {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 20px;
  min-width: 130px;
  @media (max-width: 768px) {
    min-width: unset;
    margin: 0 8px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
.tab a {
  text-align: center;
  white-space: nowrap;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: 600;
  }
}
.inviteProv {
  width: 100%;
  height: 57px;
  background-image: url('../../assets/usernavbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media (max-width: 575px) {
    background-image: url('../../assets/usernavbgmob.png');
  }
  display: flex;
  justify-content: center;
  align-items: center;
  & button {
    border: 0;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    @media (min-width: 1440px) {
      font-size: 18px;
    }
  }
  @media (max-width: 575px) {
    height: 40px;
  }
}
.freeUndl {
  position: relative;
  &::after {
    background-color: #fff;
    position: absolute;
    height: 2px;
    width: 100%;
    content: '';
    bottom: 0;
    left: 0;
  }
}
